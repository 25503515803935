import React, {Component} from 'react';
import {Line} from 'react-chartjs-2';
import './App.css';

class CountryDetail extends Component {
	constructor(props){
		super(props);
		this.state={
			cslug:props.slug,
			data: null,
			country:props.slug
		}
	}
	load_country(slug){
		const self=this;
		self.setState({cslug:slug,data:null});
		fetch("https://api.covid19api.com/total/dayone/country/"+slug).then(
			rsp=>rsp.json()).then(data=>{
				if (!data.length){return;};
				const nd={country: data[0].Country};
				const mp=[["Confirmed","red"],["Active","blue"],["Recovered","yellow"],["Deaths","black"]];
				const ds=mp.map((x,i)=>{return {"label": x[0], "lineTension": 0, "borderColor": x[1],"borderWidth":2, "pointStyle": "circle", "pointRadius":1.0, "hitRadius": 2, "hoverRadius": 10,data:[]}});
				data.filter(x=> x.Confirmed>0).map((x)=>{
					mp.map((l,i)=>{
						ds[i].data.push({"x": x.Date, "y":x[l[0]]});
					});
				});
				const ld=data[data.length-1];
				const last=mp.map(x=>{ return [x[0], ld[x[0]]]});
				nd["lastdate"]=(new Date(ld.Date)).toISOString().split("T")[0];
				nd["data"]={datasets:ds};
				nd["last"]=last;
				self.setState(nd)
			});
	}
	render() {
		if (!this.state.data){
			return ("");
		}
		const tot=Math.floor(1000.0*(this.state.last[3][1]/this.state.last[0][1])+0.5)/10;
		var lst=this.state.last.slice();
		lst.push(["% Deaths", tot]);
		return (
		<div style={{flexGrow:1}}>
			<h1>{this.state.country} {this.state.lastdate}</h1>
			<div style={{display:"flex",minWidth:"650pt"}}>
			<div style={{width:"75%"}}>
			<Line data={this.state.data} 
			options={{
				scales:{xAxes:[{type: "time",
				time: {unit: "day"}}]}
			}}
			/></div>
			<div style={{flexGrow:1}}>
			{lst.map((x,i)=>{return (
				<div key={i}>
				<h3>{x[0]}</h3>
				{x[1]}
				</div>
			)	
			})}
			</div>
			</div>
			<div style={{fontSize:"80%", "marginTop":"20pt",textAlign:"right","paddingRight":"10pt", "paddingTop":"10px", "borderTop": "1px solid #ddd"}}>Data from <a href="https://covid19api.com">https://covid19api.com</a></div>
		</div>)
	}
}

class CountrySel extends Component {
	constructor(props){
		super(props);
		this.cslugs=[];
		this.state= {
			selc: ""
		}
		this.setActive=this.setActive.bind(this);
	}
	render() {
		if (this.cslugs.length==0){
			return (
      	  	  <div className="countries">
				<div className="loading"></div>
			</div>)
		}
		const selc=this.state.selc;
		return (
      <div className="countries">
      		{this.cslugs.map((value, index)=> {
      			const isac=selc==value.Country;
      			return(<Button 
      				data={value}
      				key={value.Slug} 
      				idx={index}
      				isactive={ isac }
      				setActive={ this.setActive }
      				/>)
      		})}
	</div>
    	)

	}
	setActive(name){
		this.state.selc=name;
		this.forceUpdate();
		this.props.fn_change(name);

	}
	fetch_data(){
		const self=this;
		if (self.cslugs.length>0){
			return;
		}
		fetch("https://api.covid19api.com/summary").then(
			rsp =>rsp.json()).then(data => {
				const c=data.Countries;
				c.sort((a,b)=>{ return(a.TotalConfirmed< b.TotalConfirmed?1:-1) });
				self.cslugs=c.slice();
				self.forceUpdate();
			}).catch((error)=>{
				console.log(error);
				setTimeout(function(){self.fetch_data();}, 1);
			});

	}

	componentDidMount(){
		const self=this;
		self.fetch_data()
	}

}
class Button extends  Component {
	constructor(props) {
		super(props);
		this.select_me=this.select_me.bind(this);
	}
	select_me() {
		this.props.setActive(this.props.data.Country);
	}
	render() {
		return (<div className={`${this.props.isactive ? "sel":""}`} onClick={this.select_me } ><h1>{this.props.idx+1}. {this.props.data.Country}</h1><h2>{this.props.data.TotalConfirmed}</h2></div>);
	}
}

class Covid extends Component {
	constructor(props){
		super(props);
		this.childRef=React.createRef();
		var lst=window.location.href.match(/.*country=(\w+)/);
		lst = lst || ["","austria"];
		if (lst && lst.length>1){
			const self=this;
			const c=lst[1];
			setTimeout(function(){
				self.change_country(c);
			},10);

		}
	}
	change_country(slug){
		if (this.childRef.current){
		this.childRef.current.load_country(slug);
		}
	}
	render() {
		return(
		<div className="App">
      <CountrySel fn_change={(x)=>{this.change_country(x)}} />
      	<CountryDetail ref={this.childRef}/>
      </div>
		)
	}

}
function App() {
  return (
  	  <Covid />
  );
}

export default App;
